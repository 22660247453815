
























import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class TrezAchievementPopover extends Vue {
  telegramGames = [
    {
      name: 'Treasure Tapper',
      icon:
        'https://static.gala.games/images/telegram/icon-treasure-tapper.png',
      link: 'https://t.me/Treasure_Tapper_Bot',
    },
    {
      name: 'Music Coin',
      icon: 'https://static.gala.games/images/telegram/icon-music-coin.png',
      link: 'https://t.me/GalaMusicMinerEarnTREZBot',
    },
    {
      name: 'Meow Coin',
      icon: 'https://static.gala.games/images/telegram/icon-meow-coin.png',
      link: 'https://t.me/gala_meow_coin_bot',
    },
    {
      name: 'Dogs for the Founder',
      icon:
        'https://static.gala.games/images/telegram/icon-dogs-for-the-founder.png',
      link: 'https://t.me/dogs_for_the_founder_bot',
    },
  ];
}



















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { addMilliseconds, format } from 'date-fns';
import { TextOutput } from '~/mixins';
import { IWallet } from '~/store/inventory/types';

@Component
export default class PostMintLockExplanation extends Mixins(TextOutput) {
  @Prop(Object) readonly wallet!: IWallet;
  @Prop(Number) readonly amount!: number;
  @Prop(String) readonly tokenColor!: string;
  @Prop(Boolean) readonly hasMinted!: boolean;

  get willBeAvailableText() {
    return this.$t('components.wallet.postMintLockExplanation.locked', {
      amount: this.formattedAmounts.locked,
      token: this.symbolWithoutNetwork,
      date: this.lockExpiration,
    });
  }

  get postMintLockConfiguration() {
    return this.wallet?.mintConfiguration?.postMintLock;
  }

  get postMintBurnAmount() {
    const postMintBurnPercentage =
      this.wallet?.mintConfiguration?.postMintBurn?.burnPercentage || 0;
    return this.amount * postMintBurnPercentage;
  }

  get lockedAmount() {
    return this.amount * (this.postMintLockConfiguration?.lockPercentage || 0);
  }

  get lockExpiration() {
    return format(
      addMilliseconds(
        new Date(),
        this.postMintLockConfiguration?.expirationModifier || 0,
      ),
      'MMMM d',
    );
  }

  get immediatelyAvailableAmount() {
    return this.amount - this.postMintBurnAmount - this.lockedAmount;
  }

  get formattedAmounts() {
    const immediateNumberFormat = this.getNumberFormatString(
      this.immediatelyAvailableAmount,
    );
    const lockedNumberFormat = this.getNumberFormatString(this.lockedAmount);

    return {
      immediate: this.$numeral(
        this.immediatelyAvailableAmount,
        immediateNumberFormat,
      ),
      locked: this.$numeral(this.lockedAmount, lockedNumberFormat),
    };
  }

  get symbolWithoutNetwork() {
    return this.filterAllSymbolsText(this.wallet.symbol).replace(/\[\w*\]/, '');
  }

  getNumberFormatString(amount: number) {
    const wholeNumber = Number.isInteger(amount);

    if (amount >= 1_000_000) {
      return '0a';
    } else if (wholeNumber) {
      return '0,0';
    } else {
      const amountLength = amount.toString().length;
      return amountLength > 7 ? '0,0[.][000] a' : '0,0[.][000]';
    }
  }
}

export const tokenColors = {
  'GALA[ETH]': '255,255,255',
  'GALA[GYRI]': '255,255,255',
  'GALA[GalaChainAllowance]': '255,255,255',
  '$MUSIC[ETH]': '255,0,165',
  '$MUSIC[GYRI]': '255,0,165',
  '$MUSIC[GalaChainAllowance]': '255,0,165',
  ETH: '153, 153, 153',
  WETH: '220, 60, 122',
  GWETH: '220, 60, 122',
  USDC: '39, 117, 201',
  GUSDC: '39, 117, 201',
  'MTRM[ETH]': '64, 0, 137',
  'MTRM[GYRI]': '64, 0, 137',
  'MTRM[GalaChainAllowance]': '64, 0, 137',
  USDT: '80, 175, 149',
  GUSDT: '80, 175, 149',
  WBTC: '241, 147, 66',
  GWBTC: '241, 147, 66',
  'SILK[ETH]': '234, 114, 24',
  'SILK[GYRI]': '234, 114, 24',
  'SILK[GalaChainAllowance]': '234, 114, 24',
  'ETIME[ETH]': '60, 139, 227',
  'ETIME[GYRI]': '60, 139, 227',
  'ETIME[GalaChainAllowance]': '60, 139, 227',
  'GalaChips[ETH]': '41, 19, 49',
  'GalaChips[GYRI]': '41, 19, 49',
  'GalaChips[GalaChainAllowance]': '41, 19, 49',
  VGX: '156, 92, 194',
  GVGX: '156, 92, 194',
  DIRT: '172, 196, 83',
  'LEFC[GalaChainAllowance]': '62, 119, 117',
  'LEFC[GYRI]': '62, 119, 117',
  'LECC[GalaChainAllowance]': '62, 119, 117',
  'LECC[GYRI]': '62, 119, 117',
  'LEMIN[GYRI]': '62, 119, 117',
  'LEMIN[GalaChainAllowance]': '62, 119, 117',
  'TOLK[GYRI]': '237, 187, 63',
  'TOLK[GalaChainAllowance]': '237, 187, 63',
  'TOLK[ETH]': '237, 187, 63',
  'TREZ[GYRI]': '255, 229, 0',
  'TREZ[GalaChainAllowance]': '255, 229, 0',
};

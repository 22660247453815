import { IWallet } from '~/store/inventory/types';
import { BigNumber } from 'bignumber.js';

export const getAvailableBalance = (
  confirmed: number | string,
  unconfirmed: number | string,
  locked?: number | string,
) => {
  const confirmedBN = new BigNumber(confirmed);
  const unconfirmedBN = new BigNumber(unconfirmed);
  const lockedBN = new BigNumber(locked ?? 0);

  const lesserBalance = Math.min(
    confirmedBN.toNumber(),
    unconfirmedBN.toNumber(),
  );

  const lesserBN = new BigNumber(lesserBalance);

  return lesserBN.minus(lockedBN).toNumber();
};

export const getAvailableBalanceFromWallet = (wallet: IWallet) => {
  return getAvailableBalance(
    wallet.balance.confirmed,
    wallet.balance.unconfirmed,
    wallet.balance.locked,
  );
};

export const checkForSufficientFunds = (
  neededAmount: number,
  wallet: IWallet,
) => {
  const available = getAvailableBalanceFromWallet(wallet);
  const surplus = new BigNumber(available)
    .minus(new BigNumber(neededAmount))
    .toNumber();
  const sufficient = surplus >= 0;
  return { available, surplus, sufficient };
};

import Vue from 'vue';
import {
  Auth0Client,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js';
import { Plugin } from '@nuxt/types';

const authStateCookieName = 'gala.auth.state.change';

function resetAuthStateChangeCookie() {
  const value = Math.random().toString();
  document.cookie = `${authStateCookieName}=${value}; path=/; domain=gala.com; Max-Age=8640000;`;
}

function getAuthStateCookieValue() {
  return (
    document.cookie
      .split('; ')
      .find(row => row.startsWith(authStateCookieName))
      ?.split('=')[1] ?? ''
  );
}

function updateAuthCookieLocalStorage() {
  localStorage.setItem(authStateCookieName, getAuthStateCookieValue());
}

function getGalaAuthStateIsStale() {
  if (process.env.useSharedAuthChangeCookie !== 'true') {
    return false;
  }

  const authStateChangeCookieValue = getAuthStateCookieValue();
  const authStateChangeLocalStorageValue = localStorage.getItem(
    authStateCookieName,
  );
  const authStateChangeStoredValueIsStale =
    authStateChangeLocalStorageValue !== authStateChangeCookieValue;
  return authStateChangeStoredValueIsStale;
}

// This is all adapted from the example here: https://auth0.com/blog/complete-guide-to-vue-user-authentication/

interface IAuthUser {
  'https://gala.games/userId': string;
  'https://gala.games/displayName': string;
  'https://gala.games/permissions': Array<unknown>;
  'https://gala.games/role': string;
  'https://gala.games/ethAddress': string;
  'https://gala.games/externalEthAddress': string;
  'https://gala.games/profilePicture': string;
  'https://gala.games/connectionName': string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
}
interface IAuth {
  loginWithRedirect: (o?: RedirectLoginOptions) => Promise<void>;
  getIdTokenClaims: () => Promise<void>;
  getTokenSilently: (o?: GetTokenSilentlyOptions) => Promise<string>;
  logout: (o?: any) => Promise<void>;
  fetchUser: () => Promise<any>;
  setUserToken: (o?: any) => Promise<any>;
  isLoggedIn: () => Promise<boolean>;
  forceTokenRefresh: () => Promise<void>;
  user: IAuthUser;
}

declare module 'vue/types/vue' {
  // tslint:disable-next-line
  interface Vue {
    $auth: IAuth;
  }
}

declare module 'vuex/types' {
  // tslint:disable-next-line
  interface Store<S> {
    $auth: IAuth;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $auth: IAuth;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $auth: IAuth;
  }
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (args: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance: any;
let createdPromise: Promise<unknown> | undefined;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
} = {}) => {
  if (instance) {
    return instance;
  }

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {} as any,
        auth0Client: null as null | Auth0Client,
        popupOpen: false,
        error: null as any,
      };
    },
    methods: {
      /** Authenticates the user using the redirect method */
      async loginWithRedirect(o?: RedirectLoginOptions) {
        await createdPromise;
        resetAuthStateChangeCookie();
        return this.auth0Client!.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      async getIdTokenClaims() {
        await createdPromise;
        return this.auth0Client!.getIdTokenClaims();
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o?: GetTokenSilentlyOptions) {
        await createdPromise;
        return this.auth0Client!.getTokenSilently(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      async logout(o?: LogoutOptions) {
        await createdPromise;
        resetAuthStateChangeCookie();
        const options: LogoutOptions = {
          logoutParams: {
            returnTo: window.location.origin,
            ...o?.logoutParams,
          },
          ...o,
        };
        return this.auth0Client!.logout(options);
      },
      async forceTokenRefresh() {
        await createdPromise;
        return this.getTokenSilently({ cacheMode: 'off' });
      },
      async isLoggedIn() {
        try {
          await createdPromise;
          const token = await this.getTokenSilently({
            cacheMode: 'cache-only',
          });
          return Boolean(token);
        } catch (err) {
          return false;
        }
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object

      this.auth0Client = new Auth0Client({
        domain: process.env.auth0Domain!,
        clientId: process.env.auth0ClientId!,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        authorizationParams: {
          redirect_uri: `${window.location.origin}/loggedin`,
          audience: process.env.auth0Audience,
          scope: 'openid profile email offline_access',
        },
      });

      createdPromise = new Promise(async resolve => {
        try {
          // If the user is returning to the app after authentication..
          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            const {
              appState,
            } = await this.auth0Client!.handleRedirectCallback();

            this.error = null;

            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);
            resetAuthStateChangeCookie();
            updateAuthCookieLocalStorage();
          } else {
            const auth0CacheManager = (this.auth0Client as any).cacheManager;
            const auth0CacheHasState =
              auth0CacheManager.cache.allKeys().length > 0;
            const galaAuthStateIsStale = getGalaAuthStateIsStale();
            const shouldRequestTokens =
              auth0CacheHasState || galaAuthStateIsStale;

            if (shouldRequestTokens) {
              const auth0CacheMode = galaAuthStateIsStale ? 'off' : 'on';
              console.log(
                'Getting token from Auth0 with cache mode:',
                auth0CacheMode,
              );

              if (auth0CacheMode === 'off') {
                auth0CacheManager.clear();
              }

              await this.auth0Client!.getTokenSilently({
                cacheMode: auth0CacheMode,
              }).catch((err: unknown) => {
                console.warn(err);
              });
            } else {
              console.log('Not fetching tokens from Auth0');
            }

            updateAuthCookieLocalStorage();
          }
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          // Initialize our internal authentication state
          this.isAuthenticated = await this.auth0Client!.isAuthenticated();
          this.user = await this.auth0Client!.getUser();
          this.loading = false;
          resolve(undefined);
        }
      });
    },
  });

  return instance;
};

const auth0Plugin: Plugin = (ctx, inject) => {
  inject('auth', useAuth0());
};

export default auth0Plugin;

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PromoSweepstakes extends Vue {
  @Prop() iframeUrl!: string;

  visible = false;
  isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  active = process.env.sweepstakesActive;

  async mounted() {
    if (this.iframeUrl) {
      try {
        // const response = await fetch(this.iframeUrl, {cache: 'no-cache'});
        // if (response.ok) {
        setTimeout(() => {
          this.visible = true;
        }, 1000);
        // }
      } catch (error) {
        console.log(error);
      }
    }
  }

  close() {
    this.visible = false;
  }
}

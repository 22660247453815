import { render, staticRenderFns } from "./GasFees.vue?vue&type=template&id=0da77c08&scoped=true&"
import script from "./GasFees.vue?vue&type=script&lang=ts&"
export * from "./GasFees.vue?vue&type=script&lang=ts&"
import style0 from "./GasFees.vue?vue&type=style&index=0&id=0da77c08&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da77c08",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})

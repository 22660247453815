















































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import QRCode from 'qrcode';
import { CopyToClipboard, TextOutput } from '~/mixins';
import { build } from 'eth-url-parser';
import { State, Getter } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
const _debounce = require('lodash.debounce');

@Component({
  methods: {
    handleInput: _debounce(function() {
      //@ts-ignore
      this.updateQrCode();
    }, 1000),
  },
  components: { FullScreenDialog },
})
export default class ReceiveFunds extends Mixins(CopyToClipboard, TextOutput) {
  @Prop(String) readonly symbol!: string;
  @Prop(String) readonly address!: string;
  @Prop(String) readonly contractAddress!: string;
  @Prop(Number) readonly price!: number;
  @Prop({ type: Boolean, default: true }) readonly modal!: boolean;
  @Prop(Function) readonly closeModal!: Function;
  @Prop(Boolean) readonly specifyAmount!: boolean;
  @Prop({ type: Boolean, default: true })
  rounded!: boolean;
  @Prop(String) readonly walletIcon!: string;

  @State(profile => profile.user.preferences.currency, { namespace: 'profile' })
  readonly currency!: any;

  @Getter('showBuyCoinScreens') readonly showBuyCoinScreens!: boolean;
  amount = '';
  isInputFiat: boolean = false;

  get convertedAmount() {
    if (this.$options.filters) {
      if (!this.isInputFiat) {
        return (+this.amount * this.price).toFixed(8);
      } else return (+this.amount / this.price).toFixed(8);
    }
  }

  mounted() {
    const canvas = this.$refs.receiveCanvas;
    QRCode.toCanvas(canvas, this.address, { width: 180 }, function(error) {
      if (error) {
        console.log(error);
      }
    });
  }

  buildEthUrl() {
    const value: any = this.isInputFiat ? this.convertedAmount : this.amount;
    const url = build({
      scheme: 'ethereum',
      prefix: 'pay',
      target_address: this.address,
      parameters: {
        value: value * Math.pow(10, 18),
      },
    });
    return url;
  }

  buildErc20Url() {
    if (!this.contractAddress) return this.address;
    const url = build({
      scheme: 'ethereum',
      prefix: 'pay',
      target_address: this.contractAddress,
      parameters: {
        address: this.address,
        uint256: +this.amount * Math.pow(10, 8),
      },
      function_name: 'transfer',
    });
    return url;
  }
  buildBtcUrl() {
    const amount = this.isInputFiat ? this.convertedAmount : this.amount;
    return `bitcoin:${this.address}${amount ? '?amount=' + amount : ''}`;
  }

  updateQrCode() {
    switch (this.symbol) {
      case 'BTC':
        this.redrawCanvas(this.buildBtcUrl());
        break;
      case 'ETH':
        this.redrawCanvas(this.buildEthUrl());
        break;
      default:
        this.redrawCanvas(this.buildErc20Url());
        return;
    }
  }

  redrawCanvas(uri: string) {
    const canvas = this.$refs.receiveCanvas as HTMLCanvasElement;
    const ctx: any = canvas.getContext('2d');
    // clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // clear path
    ctx.beginPath();
    // use default comp. mode
    ctx.globalCompositeOperation = 'source-over';
    // reset alpha
    ctx.globalAlpha = 1;

    QRCode.toCanvas(canvas, uri, { width: 180 }, function(error) {
      if (error) {
      }
    });
  }

  goBack() {
    this.amount = '';
    this.closeModal('receive');
  }
}










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import TextOutput from '~/mixins/TextOutput';

@Component({})
export default class GasFees extends Mixins(TextOutput) {
  @Prop(Boolean) readonly isFetching!: boolean;
  @Prop(Array) readonly fees!: Array<{
    currency: string;
    feeAmount: string;
    feeName: string;
    tooltip: string;
  }>;

  showFees = true;

  toggleFees() {
    this.showFees = !this.showFees;
  }
}

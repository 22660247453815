import { Component, Mixins } from 'vue-property-decorator';
import { State, Action, Mutation } from 'vuex-class';
import { AuthQuery } from '~/mixins';
import { IAuth0UserIdentity } from '~/store/profile/types';
import auth0Service from '~/services/auth0.service';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import linkIdentityMutation from '~/mutations/linkIdentity.gql';
import unlinkIdentityMutation from '~/mutations/unlinkIdentity.gql';

@Component
export default class LinkIdentity extends Mixins(AuthQuery) {
  @State(profile => profile.user.auth0UserIdentities, { namespace: 'profile' })
  auth0UserIdentities!: IAuth0UserIdentity[];

  @Action('refreshUser', { namespace: 'profile' })
  refreshUser!: () => void;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  linkingCode = '';
  linkingState = '';
  requestInFlight = false;
  selectedSocialProvider = '';

  async handleConnectClick(
    connection: string,
    component: string,
    additionalQueryParams?: Array<[string, string]>,
  ) {
    let redirectUri = `${window.location.origin}/account?component=${component}&linking-identity=${connection}`;
    if (additionalQueryParams) {
      for (const param of additionalQueryParams) {
        redirectUri += `&${param[0]}=${param[1]}`;
      }
    }
    // use universal login window to authenticate with new social account
    auth0Service.authenticateUser(
      connection,
      redirectUri,
      'identity_linking_state',
    );
  }

  checkQueryParams() {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1),
    );
    const linkingCode = searchParams.get('code');
    const selectedSocialProvider = searchParams.get('linking-identity');
    const linkingState = searchParams.get('state');

    if (
      selectedSocialProvider &&
      linkingCode &&
      linkingState &&
      typeof selectedSocialProvider === 'string' &&
      typeof linkingCode === 'string' &&
      typeof linkingState === 'string'
    ) {
      this.selectedSocialProvider =
        selectedSocialProvider === 'Username-Password-Authentication'
          ? 'auth0'
          : selectedSocialProvider;
      this.linkingCode = linkingCode;
      this.linkingState = linkingState;
      this.linkIdentity();
    }
  }

  async linkIdentity() {
    if (this.linkingState !== localStorage.getItem('identity_linking_state')) {
      return;
    }

    // remove identity_linking_state so that if user refreshes the page or manually navagates back here with query params, it doesn't retry linking
    localStorage.removeItem('identity_linking_state');

    try {
      this.requestInFlight = true;

      const {
        data: oauthData,
      }: any = await auth0Service.getAccessTokenFromCode(this.linkingCode);

      const linkResponse = await this.doAuthQuery(async totpToken => {
        return this.$apollo.mutate({
          mutation: linkIdentityMutation,
          variables: {
            accessToken: oauthData.access_token,
            idToken: oauthData.id_token,
            provider: this.selectedSocialProvider,
            totpToken,
          },
        });
      }, TwoFaCheckpoint.accountModifications);

      if (linkResponse.data?.linkIdentity?.success) {
        await this.$auth.forceTokenRefresh();
        await this.refreshUser();
        this.requestInFlight = false;
      } else {
        this.requestInFlight = false;
        if (linkResponse.data?.linkIdentity?.message) {
          this.updateSnackbarErrorText(
            linkResponse.data?.linkIdentity?.message,
          );
          this.toggleErrorSnackbar();
        } else {
          throw new Error(
            linkResponse.data?.linkIdentity?.message ??
              this.$t('common.notifications.somethingWentWrong'),
          );
        }
      }
    } catch (error) {
      this.requestInFlight = false;
      this.$sentry.captureException(error);
      this.updateSnackbarErrorText(
        this.$t('common.notifications.somethingWentWrong'),
      );
      this.toggleErrorSnackbar();
    }
  }

  async handleDisconnect(provider: string, secondaryUserId: string) {
    this.selectedSocialProvider = provider;
    try {
      this.requestInFlight = true;

      const linkResponse = await this.doAuthQuery(async totpToken => {
        return this.$apollo.mutate({
          mutation: unlinkIdentityMutation,
          variables: {
            secondaryUserId,
            provider: this.selectedSocialProvider,
            totpToken,
          },
        });
      }, TwoFaCheckpoint.accountModifications);

      if (linkResponse.data?.unlinkIdentity?.success) {
        await this.refreshUser();
        this.requestInFlight = false;
      } else {
        this.requestInFlight = false;
        if (linkResponse.data?.unlinkIdentity?.message) {
          this.updateSnackbarErrorText(
            linkResponse.data?.unlinkIdentity?.message,
          );
          this.toggleErrorSnackbar();
        } else {
          throw new Error(
            linkResponse.data?.unlinkIdentity?.message ??
              this.$t('common.notifications.somethingWentWrong'),
          );
        }
      }
    } catch (error) {
      this.requestInFlight = false;
      this.$sentry.captureException(error);
      this.updateSnackbarErrorText(
        this.$t('common.notifications.somethingWentWrong'),
      );
      this.toggleErrorSnackbar();
    }
  }
}








































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import { debounce } from 'lodash';
import _omit from 'lodash.omit';
import {
  IClaimDetail,
  ITokenClaimPaymentData,
  IWallet,
} from '~/store/inventory/types';
import { getChainInfo } from '~/data/chain_info';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import InsufficientFundsList from '~/components/Wallet/InsufficientFundsList.vue';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import GasFees from '~/components/Wallet/GasFees.vue';
import PostMintLockExplanation from '~/components/Wallet/PostMintLockExplanation.vue';
import BalanceCard from '~/components/Wallet/BalanceCard.vue';
import PlayOnTelegram from '~/components/Games/PlayOnTelegram.vue';
import AuthQuery from '~/mixins/AuthQuery';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import { GalaChainMethods } from '~/types/gala-chain';
import TransactionFees from '~/mixins/TransactionFees';
import ExternalWallet from '~/mixins/ExternalWallet';
import TextOutput from '~/mixins/TextOutput';
import { checkForSufficientFunds } from '~/utils/checkForSufficientFunds';
import { tokenColors } from '~/data/tokenAssociatedColors';

@Component({
  components: {
    TwoFactorAuthInputPrompt,
    InsufficientFundsList,
    GasFees,
    RequirePasscode,
    PostMintLockExplanation,
    BalanceCard,
    PlayOnTelegram,
  },
})
export default class MintGalaChainCurrency extends Mixins(
  AuthQuery,
  ExternalWallet,
  TextOutput,
  TransactionFees,
) {
  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @Prop(Object) readonly allowanceWallet!: IWallet & {
    address: string;
    claimDetails: IClaimDetail[];
  };
  @Prop(Number) readonly availableBalance!: number;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  @Action('claimTokens', { namespace: 'inventory' })
  private claimTokens!: (payload: {
    walletPassword?: string;
    selectedItems: any[];
    gasCost: number;
    transactionFeePrice: string;
    totpToken: string;
  }) => Promise<{
    data: {
      [key: string]: {
        success: boolean;
        message: string;
        paymentData: ITokenClaimPaymentData;
      };
    };
  }>;

  successAmount: number = 0;
  userSpecifiedAmount: number | null = null;
  requestInFlight = false;
  requirePasscode = false;
  success = false;

  debounceHandler = debounce((cb: Function) => {
    cb();
  }, 600);

  /////// Computed properties ////////
  get actionButtonDisabled() {
    return !!(
      +this.amount > this.availableBalance ||
      this.isFetchingEstimatedFee ||
      this.insufficientFundsWallets.length ||
      !(+this.amount > 0)
    );
  }

  get amount() {
    return this.userSpecifiedAmount ?? this.availableBalance;
  }

  get convertHeaderText() {
    const convertFrom = this.filterAllSymbolsText(this.allowanceWallet.symbol)
      .replace(/\[\w*\]/, '')
      .replace(/^\$/, '');
    const convertTo = this.filterAllSymbolsText(
      this.targetWallet?.symbol?.replace(/^\$/, '') ?? '',
    );
    return this.$t('components.wallet.mintGalachainCurrency.convertHeader', {
      convertFrom,
      convertTo,
    });
  }

  get convertToSymbol() {
    const symbol = this.allowanceWallet.symbol;
    const regEx = /\[\w*\]/;
    if (regEx.test(symbol)) {
      return symbol.replace(regEx, '[GYRI]');
    }
    return symbol;
  }

  get feesWithExplanation() {
    const burnToMintPercentage =
      this.mintConfig?.postMintBurn?.burnPercentage ??
      this.mintConfig?.preMintBurn?.burnPercentage;

    return this.transactionFeeWallets
      .map(fw => {
        const isGasFee = fw.currency?.includes('GALA');
        return {
          ...fw,
          feeName: isGasFee
            ? this.$t('components.wallet.gasFees.gasFee')
            : this.$t('components.wallet.gasFees.conversionFee'),
          tooltip: isGasFee
            ? this.$t('components.wallet.gasFees.gasFeeTooltip')
            : burnToMintPercentage
            ? this.$t('components.wallet.gasFees.conversionFeeTooltip', {
                percentage: burnToMintPercentage * 100,
              })
            : null,
        };
      })
      .filter(f => f.tooltip);
  }

  get hasGasFees() {
    return this.targetChainInformation?.hasSendGasFees ?? true;
  }

  get insufficientFundsWallets() {
    return this.transactionFeeWallets
      .filter(w => {
        // @ts-ignore: w.wallet will never be undefined
        const { sufficient } = checkForSufficientFunds(+w.feeAmount, w.wallet);

        if (
          this.targetWallet?.symbol === w.wallet?.symbol &&
          this.mintConfig?.postMintBurn?.burnPercentage &&
          !this.mintConfig.preMintBurn?.burnPercentage
        ) {
          return false;
        }
        return !sufficient;
      })
      .map(w => ({ wallet: w.wallet, isFee: true }));
  }

  get mintConfig() {
    return this.targetWallet?.mintConfiguration;
  }

  get symbolWithoutNetwork() {
    return this.filterAllSymbolsText(
      this.allowanceWallet.symbol.replace(/\[\w*\]/, '').replace(/^\$/, ''),
    );
  }

  get targetChainInformation() {
    return getChainInfo(this.targetWallet?.network || 'GYRI');
  }

  get targetWallet() {
    return this.wallets.find(w => w.symbol === this.convertToSymbol);
  }

  get tokenColor() {
    const symbol = this.allowanceWallet.symbol;
    if (symbol && tokenColors[symbol as keyof Object]) {
      return tokenColors[symbol as keyof Object];
    }

    return '';
  }
  /////////////////////////////

  /////// Input field rules /////////
  requiredField(val: string) {
    if (!val) {
      return this.$t('common.notifications.requiredField');
    }

    return true;
  }

  positiveValue(val: string) {
    if (Number(val) <= 0) {
      return this.$t('components.wallet.convertCoin.positiveValue');
    }

    return true;
  }

  maxValue(val: string | number) {
    val = Number(val);
    const message = this.$t(
      'components.wallet.convertCoin.amountExceedsAvailableBalance',
      { symbol: this.filterAllSymbolsText(this.allowanceWallet.symbol) },
    );

    if (val > this.availableBalance) {
      return message;
    }

    return true;
  }
  ///////////////////////////

  amountChanged(newAmount: number) {
    this.userSpecifiedAmount = newAmount;
  }

  handleSubmitClick() {
    if (!this.hasGyriPassphrase) {
      this.toggleCreateWalletPrompt({
        show: true,
        walletType: 'gyri',
      });
      return;
    }

    if (this.insufficientFundsWallets.length) {
      return;
    }

    this.requirePasscode = true;
  }

  async onTransactionActionComplete(args: {
    success: boolean;
    message: string;
    results: any;
  }) {
    this.successAmount = +this.amount;
    this.requirePasscode = false;
    this.success = true;
    this.$emit('success', true);
  }

  async onTransactionActionError(args: { message: string; error: Error }) {
    console.warn(args.error);
    this.updateSnackbarErrorText(args.message);
    this.toggleErrorSnackbar(true);
  }

  async claimItems(walletPassword: string) {
    const galaFee = this.transactionFeeWallets.find(
      w => w.currency === 'GALA[GYRI]',
    );

    const result = await this.doAuthQuery(async totpToken => {
      try {
        const selectedItems =
          this.setClaimQuantities(this.allowanceWallet.claimDetails) || [];
        const results = await this.claimTokens({
          walletPassword,
          selectedItems,
          gasCost: this.tokenClaimFee,
          transactionFeePrice: (galaFee?.feeAmount ?? 0).toString(),
          totpToken,
        });

        if (!results?.data.claimTokensV2?.success) {
          const message =
            results?.data.claimTokensV2?.message ??
            this.$t('common.notifications.somethingWentWrong');
          this.updateSnackbarErrorText(message);
          this.toggleErrorSnackbar();
          const error = new Error(message);
          throw error;
        }

        return true;
      } catch (error) {
        this.$sentry.captureException(error);
        this.requestInFlight = false;
        throw error;
      }
    }, TwoFaCheckpoint.transactions);

    if (!result) {
      throw new Error('2FA canceled');
    }
  }

  setClaimQuantities(claimDetails: IClaimDetail[]) {
    let selectedQuantityRemaining = +this.amount;
    return claimDetails.map(claim => {
      const claimQuantity = +claim.quantity;
      if (selectedQuantityRemaining >= claimQuantity) {
        selectedQuantityRemaining -= claimQuantity;
        return { ...claim, tokenId: this.allowanceWallet.tokenId };
      } else {
        const updatedClaim = {
          ...claim,
          quantity: selectedQuantityRemaining,
          tokenId: this.allowanceWallet.tokenId,
        };
        selectedQuantityRemaining = 0;
        return updatedClaim;
      }
    });
  }

  @Watch('amount', { immediate: true })
  onAmountChange(amount: string) {
    this.debounceHandler(() => {
      if (+amount > 0 && +this.amount <= this.availableBalance) {
        const symbolMatch = this.convertToSymbol.match(/^(\w*)\[\w*\]$/);
        const tokenClassKey = this.targetWallet?.tokenClassKey || {
          collection: symbolMatch ? symbolMatch[1] : '',
          category: 'Unit',
          type: 'none',
          additionalKey: 'none',
        };

        const transactionData = {
          method: GalaChainMethods.MintToken,
          tokenInstances: [
            {
              quantity: this.amount.toString(),
              tokenInstanceKey: {
                ..._omit(tokenClassKey, '__typename'),
                instance: '0',
              },
            },
          ],
        };
        this.getGalachainTransactionFeeEstimates([transactionData]);
      }
    });
  }
}










































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SweepstakesCallout extends Vue {
  @Prop() price?: number;
  @Prop() visible?: boolean;
  @Prop({ default: 'md' }) size?: 'md' | 'sm' | 'xs';
  @Prop() description?: string;
  @Prop() subDescription?: string;

  active = process.env.sweepstakesActive;

  get numTickets(): number {
    return Math.floor((this.price || 0) / 100);
  }

  get descriptionText(): string {
    if (this.description || this.description === '') {
      return this.description;
    } else if (this.numTickets > 0) {
      return this.$t('components.store.sweepstakes.youReceiveNumberTickets', {
        number: this.numTickets,
      }).toString();
    } else {
      return this.$t(
        'components.store.sweepstakes.youReceiveTickets',
      ).toString();
    }
  }

  get subDescriptionText(): string {
    if (this.subDescription || this.subDescription === '') {
      return this.subDescription;
    }
    return this.$t(
      'components.store.sweepstakes.freeTicketWithBurn',
    ).toString();
  }
}
















































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import LanguageSwitcher from '~/components/LanguageSwitcher.vue';
import NumberFormatSelector from '~/components/NumberFormatSelector.vue';
import GalaLogo from '../UIComponents/GalaLogo.vue';
import SignUp from '~/components/UIComponents/SignUp.vue';

@Component({
  components: { LanguageSwitcher, NumberFormatSelector, GalaLogo, SignUp },
})
export default class GalaFooter extends Vue {
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State('discordLink') readonly discordLink!: string;

  routesToHideFooterOn: string[] = [];
  footerSignupTagId = '74,671';

  get hideFooter() {
    return this.$route.name
      ? this.routesToHideFooterOn.includes(this.$route.name)
      : false;
  }

  get galaComUrl() {
    return process.env.galaComUrl;
  }

  onTrackNavigation(url: string) {
    this.$ua.trackNavigationEvent({
      to: url,
      from: this.$route.fullPath,
    });
  }
}














































import { Component, Mixins, Prop } from 'vue-property-decorator';
import numeral from 'numeral';
import TextOutput from '~/mixins/TextOutput';

@Component({})
export default class BalanceCard extends Mixins(TextOutput) {
  @Prop(String) readonly color!: string;
  @Prop(String) readonly icon!: string;
  @Prop(String) readonly symbol!: string;
  @Prop(Number) readonly availableBalance!: number;
  @Prop(String) readonly address!: string;
  @Prop(String) readonly network!: 'ETHEREUM' | 'GYRI' | 'GYRI_TREASURE_CHEST';

  ready = false;

  get formattedAmount() {
    const wholeNumber = Number.isInteger(this.availableBalance);
    let formatString = '0,0[.][000]';

    if (this.availableBalance >= 1_000_000_000) {
      formatString = '0[.][000000000]a';
    } else if (wholeNumber) {
      formatString = '0,0';
    } else {
      const amountStr = this.availableBalance.toString();
      const [whole] = amountStr.split('.');
      const allowedDecimals = 15 - whole.length;
      const decimalArray = Array(allowedDecimals)
        .fill(0)
        .join('');
      formatString = `0,0[.][${decimalArray}]`;
    }
    return this.$numeral(this.availableBalance, formatString);
  }

  get balanceFontSize() {
    const elevenPlusCharacters = this.formattedAmount.length > 10;

    if (this.$vuetify.breakpoint.smAndDown) {
      return elevenPlusCharacters ? '20px' : '28px';
    }

    return elevenPlusCharacters ? '24px' : '32px';
  }

  get formattedNetwork() {
    switch (this.network) {
      case 'ETHEREUM':
        return this.$t('common.transactions.eth');

      case 'GYRI':
        return this.$t('common.transactions.galaChain');

      case 'GYRI_TREASURE_CHEST':
        return this.$t('common.transactions.galaChainAllowance');

      default:
        return '';
    }
  }

  get gradientStyles() {
    const card = this.$refs.balanceCard as HTMLVideoElement;
    const radialGradientSize = Math.floor(
      ((this.ready && card?.offsetWidth) || 200) * 0.6,
    );
    const radialGradientPosition = radialGradientSize * 0.6 * -1;
    return {
      background: `linear-gradient(120deg, rgba(${this.color}, 0.1) 16%, rgba(255,255,255, 0.05) 100%), radial-gradient(circle ${radialGradientSize}px at 50% ${radialGradientPosition}px, rgba(${this.color}, 0.2) 40%, rgba(255, 255, 255, 0) 100%)`,
    };
  }

  get networkWalletText() {
    return this.$t('components.wallet.balanceCard.wallet', {
      network: (this.formattedNetwork as string).replace(/ .*/, ''),
    });
  }

  get showTooltip() {
    return (
      numeral(this.formattedAmount).value() !==
      numeral(this.availableBalance).value()
    );
  }

  get symbolToDisplay() {
    return this.filterAllSymbolsText(
      this.symbol.replace(/\[\w*\]/, '').replace(/^\$/, ''),
    );
  }

  get truncatedAddress() {
    return `${this.address.slice(0, 12)}....${this.address.substr(
      this.address.length - 4,
      this.address.length,
    )}`;
  }

  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 10);
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{class:{
    'white--text new-wallet': true,
    'pa-3': _vm.$vuetify.breakpoint.mdAndUp,
    modalBackground: !_vm.transparentBg,
  },attrs:{"column":"","justify-space-between":_vm.$vuetify.breakpoint.xs,"align-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-layout',{staticClass:"mx-auto",attrs:{"column":"","align-center":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"shrink":"","tag":"h2"}},[_vm._v("\n        "+_vm._s(_vm.modalTitle)+"\n      ")]),_vm._v(" "),_c('v-flex',{attrs:{"shrink":""}},[_c('v-layout',{staticClass:"text-center",attrs:{"column":"","align-center":""}},[(!_vm.success)?_c('v-stepper',{attrs:{"flat":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.passValid && _vm.confirmPassValid,"step":"1","color":"accentBlue"}}),_vm._v(" "),_c('v-divider',{class:{
                  active: _vm.step > 1,
                  accentLight: _vm.step <= 1,
                  'dark-mode': _vm.darkMode,
                }}),_vm._v(" "),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2","color":"accentBlue"}}),_vm._v(" "),_c('v-divider',{class:{
                  active: _vm.step > 2,
                  accentLight: _vm.step <= 2,
                  'dark-mode': _vm.darkMode,
                }}),_vm._v(" "),_c('v-stepper-step',{attrs:{"complete":_vm.mnemonicConfirmed,"step":"3","color":"accentBlue"}})],1),_vm._v(" "),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('span',{class:{
                    'text-left description-text': true,
                    dark: _vm.darkMode,
                  }},[_vm._v("\n                  "+_vm._s(_vm.$t('components.newWallet.newWallet.transferCodeMsg', {
                      network: _vm.gyriPassphrase ? _vm.galaChainName : '',
                    }))+"\n                ")]),_vm._v(" "),_c('v-divider',{class:{ 'accentLight my-6': true, 'dark-mode': _vm.darkMode }}),_vm._v(" "),_c('validated-password',{attrs:{"onCurrentStep":_vm.step === 1,"label":_vm.$t('common.transactions.transferCode'),"darkMode":_vm.darkMode},on:{"pass-change":_vm.handlePassChange,"pass-valid-change":_vm.handlePassValid}}),_vm._v(" "),_c('confirm-password',{attrs:{"label":_vm.$t('components.newWallet.newWallet.confirmTransferCode'),"onCurrentStep":_vm.step === 1,"password":_vm.password,"darkMode":_vm.darkMode},on:{"confirm-pass-valid-change":function (isValid) { return (_vm.confirmPassValid = isValid); },"enter-continue":_vm.handleContinue}}),_vm._v(" "),_c('v-layout',{attrs:{"pl-3":""}},[_c('div',{class:{
                      selected: _vm.ageConfirmation,
                      'select-box': true,
                      dark: _vm.darkMode,
                    },on:{"click":function($event){_vm.ageConfirmation = !_vm.ageConfirmation}}},[(_vm.ageConfirmation)?_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("\n                      fas fa-check\n                    ")]):_vm._e()],1),_vm._v(" "),_c('div',{class:{ 'ml-2 description-text': true, dark: _vm.darkMode }},[_vm._v("\n                    "+_vm._s(_vm.$t('components.newWallet.newWallet.ageCertification'))+"\n                  ")])])],1),_vm._v(" "),_c('v-stepper-content',{staticClass:"px-3 pt-0",attrs:{"step":"2"}},[_c('display-recovery-phrase',{attrs:{"brand":_vm.brand,"onCurrentStep":_vm.step === 2,"showRecoveryPhrase":_vm.showRecoveryPhrase,"mnemonic":_vm.newMnemonic},on:{"on-current-step":function($event){_vm.showRecoveryPhrase = false},"acknowledge-mnemonic":function (ack) { return (_vm.acknowledgedMnemonic = ack); }}})],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('confirm-mnemonic',{attrs:{"saving":_vm.isSavingWallet,"step":_vm.step,"assignedStep":3,"mnemonic":_vm.newMnemonic},on:{"mnemonic-confirmed":function (confirmed) { return (_vm.mnemonicConfirmed = confirmed); }}}),_vm._v(" "),(_vm.isSavingWallet)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1)],1)],1):_c('v-layout',{staticClass:"px-6",attrs:{"column":""}},[_c('GradientHexagon',{staticClass:"mx-auto my-12",attrs:{"uniqueId":"dynamite","imgSrc":_vm.dynamite,"width":"200px","minWidth":"200px","imgScale":60}}),_vm._v(" "),_c('h2',[_vm._v("\n              "+_vm._s(_vm.$t('components.newWallet.newWallet.completionMsg', {
                  displayName: _vm.user.displayName,
                }))+"\n            ")]),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('components.newWallet.newWallet.setupComplete')))])],1)],1)],1)],1)],1),_vm._v(" "),(!_vm.isSavingWallet)?_c('v-flex',{staticClass:"px-3 control-buttons",attrs:{"shrink":""}},[_c('v-layout',{attrs:{"my-3":""}},[(_vm.step < 4)?_c('v-flex',{attrs:{"xs4":"","shrink":""}},[_c('button',{class:{
            'gala-btn large full-width font-weight-bold rounded-btn cancel-btn': true,
            'accentLight modalBackground--text': !_vm.darkMode,
            'dark-mode': _vm.darkMode,
          },on:{"click":_vm.handleBack}},[_vm._v("\n          "+_vm._s(_vm.backBtnText)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('v-flex',{attrs:{"xs8":_vm.step < 4,"ml-2":""}},[_c('button',{staticClass:"gala-btn large full-width font-weight-bold rounded-btn",class:_vm.continueBtnClass,attrs:{"id":"wallet-setup-creation","disabled":_vm.continueBtnDisabled},on:{"click":_vm.handleContinue}},[_vm._v("\n          "+_vm._s(_vm.continueBtnText)+"\n        ")])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-layout',{class:{
    'modal-content-container': !_vm.standAloneFormatting,
    'px-10 pt-8 pb-12': _vm.$vuetify.breakpoint.smAndUp && !_vm.standAloneFormatting,
    'px-6 pt-6 pb-12': _vm.$vuetify.breakpoint.xs && !_vm.standAloneFormatting,
    'mt-5 pt-6': _vm.$vuetify.breakpoint.xs && _vm.standAloneFormatting,
    'pt-4': _vm.standAloneFormatting,
  },attrs:{"column":"","fill-height":"","align-center":"","justify-center":""}},[(!_vm.standAloneFormatting)?_c('div',{staticClass:"d-flex justify-space-between mb-16 password-header"},[_c('h1',{staticClass:"balance-modal-header mr-3"},[_vm._v("\n      "+_vm._s(_vm.$t('components.wallet.requirePasscode.enterYourTransferCode'))+"\n    ")]),_vm._v(" "),_c('button',{staticClass:"rounded-icon-btn close-btn",attrs:{"aria-label":"close send modal"},on:{"click":function () { return _vm.$emit('closeModal'); }}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("close")])],1)]):_vm._e(),_vm._v(" "),_c('v-flex',{staticStyle:{"width":"100%"},attrs:{"shrink":"","align-self-center":""}},[(!_vm.hideHeading)?_c('h2',{staticClass:"transfer-funds-drawer text-xs-center mb-3"},[_vm._v("\n      "+_vm._s(_vm.subheading
          ? _vm.subheading
          : _vm.$t('components.wallet.requirePasscode.enterTransferCode'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.standAloneFormatting)?_c('v-text-field',{attrs:{"autofocus":true,"filled":"","light":"","background-color":"white","color":"accentBlue","flat":"","label":_vm.$t('common.transactions.transferCode'),"autocomplete":"current-password","append-icon":_vm.showPass ? 'visibility_off' : 'visibility',"type":_vm.showPass ? 'text' : 'password',"rules":_vm.rules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.performAction($event)},"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.encryptionPasscode),callback:function ($$v) {_vm.encryptionPasscode=$$v},expression:"encryptionPasscode"}}):_c('v-text-field',{staticClass:"mt-3",attrs:{"autofocus":true,"filled":"","background-color":"#1e1e1e","color":"accentTextLightGray","flat":"","label":_vm.$t('common.transactions.transferCode'),"autocomplete":"current-password","append-icon":_vm.showPass ? 'visibility_off' : 'visibility',"type":_vm.showPass ? 'text' : 'password',"rules":_vm.rules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.performAction($event)},"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.encryptionPasscode),callback:function ($$v) {_vm.encryptionPasscode=$$v},expression:"encryptionPasscode"}})],1)],1),_vm._v(" "),_c('v-col',{class:{ 'd-flex justify-center': !_vm.standAloneFormatting },attrs:{"cols":_vm.standAloneFormatting ? 'auto' : 12}},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:(!_vm.encryptionPasscode),expression:"!encryptionPasscode"}],class:{
            'rounded-btn white-btn large mt-10': !_vm.standAloneFormatting,
            'gala-btn large ga-transfer-code': _vm.standAloneFormatting,
          },attrs:{"disabled":_vm.buttonDisabled},on:{"click":_vm.performAction}},[_vm._v("\n          "+_vm._s(_vm.buttonText)+"\n        ")])])],1)],1)],1):_c('v-layout',{class:{
    'pt-4 modal-content-container circular-progress':
      !_vm.standAloneFormatting && !_vm.customLoadingVideoUrl,
  },attrs:{"column":"","fill-height":"","align-center":"","justify-center":""}},[(_vm.customLoadingVideoUrl)?_c('video',{attrs:{"loop":"","muted":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.customLoadingVideoUrl,"type":_vm.customLoadingVideoType}})]):_c('v-progress-circular',{staticClass:"mx-16 my-10",attrs:{"size":70,"width":7,"color":"accentBlue","indeterminate":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }




































import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ConfirmPassword extends Vue {
  @Prop(Boolean) darkMode?: boolean;
  @Prop(String) readonly password!: string;
  @Prop(Boolean) readonly onCurrentStep!: boolean;
  @Prop({ default: 'Confirm Password' })
  readonly label!: string;
  confirmPassInput = '';
  showPass = false;

  get valid() {
    return this.password !== '' && this.confirmPassInput === this.password;
  }

  handleEnter() {
    if (this.valid) {
      this.$emit('enter-continue');
    }
  }

  @Emit('confirm-pass-valid-change')
  passValidChanged(passValid: boolean) {
    return passValid;
  }

  @Watch('valid')
  handleValid(isValid: boolean) {
    this.passValidChanged(isValid);
  }
  @Watch('onCurrentStep')
  handleonCurrentStepChange(onCurrentStep: boolean) {
    if (onCurrentStep) {
      (this.$refs.confirmPass as any).focus();
    } else {
      (this.$refs.confirmPass as any).blur();
    }
  }
}
